.pos-dashboard {
  .pos-scorecard {
    margin-top: 20px;
    .filters {
      background: #0093d6;
      padding: 10px 15px 25px 15px;
      color: #fff;

      .title {
        margin-bottom: 6px;
      }

      .button-group {
        button {
          background: #fff;
          color: #000;
          border: 0;
          padding: 0;
          height: 29px;
          width: 33.33%;

          span {
            display: inline-block;
            width: 100%;
          }

          &.active {
            background: #434343;
            color: #fff;
          }

          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
             span{
              &.line{
                border-right: 1px solid gray; 
              }
            }
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            span{
              &.line{
                border-left: 1px solid gray; 
              }
            }
          }
        }
      }
    }
  }

  .grid {
    margin-top: 20px;
    margin-bottom: 20px;

    table {
      width: 100%;
      border: 1px solid #ccc;
      table-layout: fixed;

      td {
        border: 1px solid #ccc;
        padding: 20px;
        text-align: center;
      }
    }
  }


  .kpi-section, .reports-section{
    .ibox-content{
      padding: 15px;
    }
    h2{
      margin: 0;
    }
    sub {
      font-size: 45%;
    }
    .hide-when-empty{
      &:empty{
        display: none;
      }
    }
  }
  .reports-section{
    .table-kpi{
      .quarters{
        td{
          width: 25%;
        }
      }
    .thirds{
        td{
          width: 33.33%;
        }
      }
    }
    .section-title {
      margin-bottom: 15px;
    }
  }
  .kpi-section{
      padding:20px 0px; 
    h4{
      padding-top: 5px;
    }
    .widget{
      min-height: 140px;
      max-height: 140px;
      .table-kpi{
        margin: 0;
      }
    }
  }
  //pie
  .widget{
    .pie-chart{
      background: white;
      border:1px solid #3dc7ab;
    }
    .align-right{
      text-align: right;
    }
    h2{
      text-align: right;
    }
  }
  .ibox{
    margin-top: 5px;
    .form-group{
      input{
        padding-left: 5px
      }
    }
    h3{
      background-color: #fff;
      margin: 0;
      padding: 10px;
    }
  }

  .pos-report{
    .placeholder-graph{
      border: 1px solid black;
      height: 400px;
    }
    p{
      font-size: 16px;
    }
  }
}
