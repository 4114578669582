#toast-container {
  position: fixed;
  top: 30px;
  right: 50px;
  width: 300px;
  z-index: 100000;

  .toast {
    padding: 10px 20px 10px 50px; // Need high left padding for icon
    font-size: 1.4em;
    position: relative;

    .toast-close-button {
      float: right;
      background: none;
      padding: 0;
      margin-top: -5px;
      margin-right: -10px;
    }
  }
}
