$header-height: 45px;

.path-logger {
  height: 100%;
  cursor: pointer;
  //background-color: #21252b;

  &__header {
    height: $header-height;
    color: #337ab7;
    display: flex;
    align-items: center;
  }

  &__title {
    height: $header-height;
    background-color: #555;
    color: #337ab7;
    display: flex;
    align-items: center;
  }

  &__list {
    height: calc(100% - #{$header-height});
    overflow-y: scroll;
    padding: 0;
    margin: 0;
  }

  &__list-item {
    list-style: none;
    padding: 0.5rem 1rem;
    max-width: 100%;

    &:hover {
      background-color: #EEE;
    }

    &--selected {
      background-color: #AAA;

      &:hover {
        background-color: #BBB;
      }
    }

    &-wrapper {
      display: flex;
    }

    &-notes {
      width: 100%;
      padding-left: 40px;
      color: #fff;
    }

    &-note {
      margin: 4px 0;
    }

    &-num,
    &-id,
    &-description {
      margin-right: 0.5rem;
    }

    &-description {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 0 1 auto;
      min-width: 0;
    }

  }

}
