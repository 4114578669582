.asset-list {
  height: 190px;
  margin-bottom: 20px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  .file-box {
    display: inline-block;
    float: none;
    vertical-align: top;
  }
}

.asset-upload {
  border-radius: 3px;
  border-style: dashed;
  border-width: 2px;
  height: 158px;
  padding: 0;
  white-space: normal;
  width: 100%;

  &:hover {
    background-color: #efefef;
    border-color: rgb(33, 150, 243);
    cursor: pointer;
  }

  p {
    margin: 60px auto 0px;
    text-align: center;
    width: 150px;
  }
}

.file:hover {
  border-color: rgb(33, 150, 243);
  cursor: pointer;
}
