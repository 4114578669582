.force-graph {
  width: 100%;
  height: 100%;
  background-color: #fff;

  .links line {
      stroke: #CCC;
      stroke-opacity: 0.6;
      stroke-width: 2;

    &.iterative {
      stroke: #090;
    }

    &.async-action {
      stroke: #59F;
    }

    &.iterative-async-action {
      stroke: #0C0;
    }

    &.parent {
      stroke-width: 3;
      stroke-dasharray: 2, 2;
    }
  }

  .nodes .node {
    cursor: pointer;

    circle {
      fill: #3498db;
      stroke: #fff;
      stroke-width: 1.5px;
    }

    text {
      font-size: 8px;
      font-weight: normal;
    }

    &.did-run circle {
      fill: #f1c40f;
    }

    &.entry-point circle {
      fill: #2ecc71;
    }

    &[selected] circle {
      fill: #9ce00a;
    }
  }
}
