.test-componentes {
  .left-menu {
    display: flex;
    left: 12px;
    z-index: 50;
    .warden-test__sidebar {
      width: 100%;
      border-right: 1px solid lightgrey;
      background-color: #fff;
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 20;
      display: flex;
      padding: 0 20px 30px;
      flex-direction: column;
      height: calc(100vh - 61px);
      .btn-secondary {
        margin: 15px 0 0;
      }
    }
    .lists {
      max-height: calc(100vh - 276px);
      overflow-y: auto;
      margin-bottom: 30;
      flex-grow: 2;
      .tests {
        list-style-type: none;
        input {
          margin-right: 5px;
          position: relative;
          top: 3px;
        }
      }
    }
  }
}

.trace-container {
  height: 100vh;

  .path-logger-container{
    height: 40vh;
  }
}
