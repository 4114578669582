.checkbox-list {
  clear: both;
  margin-bottom: 50px;

  .checkbox-wrapper {
    float: left;
    width: 100px;
  }
}

.top-spacing {
  margin-top: 20px;
}

.btn-group-report{
  padding-bottom: 15px;
  padding-top: 15px;
  text-align: right;
}

.sales-performance-report{
  .amcharts-export-menu-top-right {
      margin-top: 45px;
      right: -7px;
  }
}

.no-data-report {
    font-weight: 600;
    text-align: center;
    width: 100%;
}

.blocked-page-error { 
    color: #2F4050;
    font-size: 19px;
    font-weight: 600;
    padding-top: 105px;
    text-align: center;
}