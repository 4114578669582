.pool-score-page {
  font-size: 14px;

  .form-group label {
    font-weight: bold !important;
  }
}

.parameter-input .data-cell,
.fixed-input .data-cell {
  padding: 0;
}

.test-sidebar {
  border-left: 2px solid #263238;
  padding: 0 10px;
}

.score-category {
  color: #333;
}

button.btn + button.btn {
  margin-left: 15px;
}

.test-success {
  color: #4caf50;
}

.test-summary {
  cursor: pointer;
}

.test-error {
  color: #f44336;
}

label {
  font-size: 14px;
}

.score-category {
  font-weight: bold;
  min-width: 150px;
  text-transform: uppercase;
}

.form-control-static {
  font-size: 14px;
}

.form-control {
  font-size: 14px;
  padding: 0 5px;
}

.editable-checkbox {
  position: relative;

  i,
  input {
    display: block;
    float: left;
    margin: 2px 10px 5px 0;
  }

  .editable-checkbox-label {
    float: left;
    margin-top: -1.4em;
    max-width: 85%;
    text-indent: 0;
  }
}

@media (max-width: 1024px){
  .pool-score-page {
    .smaller-font-table{
      .parameter-input{
        font-size: 10px;
      }
    }
  }
}
@media (max-width: 1200px){
  .pool-score-page {
    .smaller-font-table{
      .parameter-input{
        font-size: 11px;
      }
    }
    .table-striped{
      tbody{
        tr{
          td {
            height: 100%;
            .editable-textfield, input {
              height: 100%;
            }
          }
        }
      }
    }
  }
}
