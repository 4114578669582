.navigation-tabs {
  border-bottom: solid 3px $blue3;
  display: table;
  margin-bottom: 25px;
  width: 100%;

  > a {
    background: $box-color;
    border-right: 2px solid $white;
    color: $main-text-color;
    cursor: pointer;
    display: table-cell;
    font-weight: bold;
    max-width: 100px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:last-child {
      border-right: 0;
    }

    &:hover,
    &.active {
      background-color: $blue3;
      color: $white;
      text-decoration: none;
    }
  }
}

.nav-tabs {
  border-bottom: 0;
}

