.react-datepicker__input-container {
  position: relative;
}

.react-datepicker__input-container input {
  position: relative;
  font-size: 13px;
  border-radius: 4px;
  border: 1px solid;
  border-color: #ccc;
  line-height: 34px;
  height: 36px;
  padding: 6px 10px 5px;
}

.react-datepicker__input-container input:focus {
  outline: none;
  border-color: #ccc;
}

.react-datepicker__input-container input:not(:valid) ~ .close-icon {
  display: none;
}