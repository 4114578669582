.tab-announcement ul {
  border-bottom: 1px solid #e7eaec;
}

/* Active tab */
.tab-announcement ul > li.active > a,
.tab-announcement ul > li.active > a:hover,
.tab-announcement ul > li.active > a:focus {
  border: 0px;
  border-bottom: 3px solid #555555;
}

/* Disabled tab */
.tab-announcement ul > li.disabled > a {
  background-color: lightgray;
  color: darkgray;
}

.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

.card {
  padding: 10px;
  border-top: 1px solid #e7eaec;
  border-collapse: collapse;
  cursor: pointer;
  
  &:last-child {
    border-bottom: 1px solid #e7eaec;
  }
}

.card:hover {
  background: #f9f9f9;
}

.card-container > .card:nth-child(odd) {
  background: #f9f9f9;
}

.card-img {
  width: 161px;
  height: 92px;
  height: auto;
}

.card-text {
  margin-left: 1.50rem;
  margin-bottom: 1.25rem;
}

.card-title {
  font-weight: bold;
}

.tab-content-wrapper {
  margin-top: 15px;
  margin-bottom: 8px;
}

.file-selected {
  border: 2px solid $blue3;
}

.file-box-wrapper {
  margin-top: 20px;
}

.file-box-wrapper > .file > div.active {
  border-top: 3px solid $blue3;
}