$menu-content-container-border-color: #eae8e8;

.header {
    background-color: white;
    margin: 0;
    padding: 10px 25px;
    .vertical-align {
        align-items: center;
        display: flex;
    }
    .app-select {
        margin-left: 25px;
        z-index: 1000;
        img {
            opacity: .7;
        }
    }
    .left-panel {
        text-align: left;
        img {
            height: 40px;
        }
    }
    .right-panel {
        text-align: right;
        i, .user-name, .popover-menu, .app-select {
            display: inline-block;
        }
        .user-name {
            cursor: pointer;
            font-weight: 600;
            margin: 0 5px 0 10px;
        }
    }
    .right-panel .popover-menu {
        position: relative;
        .fa-chevron-down {
            cursor: pointer;
            color: #52b6e2;
        }
        .menu-content-container {
            background-color: white;
            border: solid 1px $menu-content-container-border-color;
            border-radius: 5px;
            display: none;
            position: absolute;
            right: -20px;
            text-align: left;
            top: 30px;
            width: 150px;
            z-index: 1000;
            &.open {
                display: block;
            }
        }
        .menu-content-container .item {
            cursor: pointer;
            display: block;
            padding: 10px 15px;
            width: 100%;
            i {
                color: #52b6e2;
                margin-right: 5px;
            }
            &:hover {
                color: #061f5c;
            }
        }
        .menu-content-container .arrow-up {
            position: absolute;
            bottom: 100%;
            right: 10px;
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 15px solid $menu-content-container-border-color;
            &:after {
              content: '';
              display: block;
              position: absolute;
              bottom: -20px;
              left: -19px;
    
              width: 0;
              height: 0;
    
              border-left: 19px solid transparent;
              border-right: 19px solid transparent;
              border-bottom: 19px solid #fff;
            }
          }
    }
}