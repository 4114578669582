// adapted from https://github.com/alexanderwallin/react-player-controls/blob/master/docs/src/sass/_controls.scss

$icon-size: 20px;

$black: #3D463F;
$gray: #555;
$gray-ligher: #AAA;
$white: #fff;
$blue: #3498db;
$green: #72D687;

$color-hover: lighten($gray, 10%);
$color-active: fade-out($color-hover, 0.5);

.player-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  width: 85%;
  margin: 0 auto;

  &__controls-container {
    position: absolute;
    right: 25px;
    top: 10px;
  }

  &__progress-wrapper {
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
  }

  &__progress-container {
    flex-grow: 2;
    flex-shrink: 0;
    position: relative;
    margin: 0 10px;
  }

  &__step-indicator {
    white-space: nowrap;
    position: relative;
    right: -30px;
  }

  &__progress-hover {
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;

    position: absolute;
    bottom: 22px;
    transform: translateX(-50%);
  }

  //
  // Buttons
  //
  button {
    background-color: $white;
    border-radius: 4px;
    border: 2px solid $blue;
    color: $blue;
    display: block;
    font-size: 15px;
    height: 35px;
    margin-bottom: 5px;
    text-align: center;
    width: 35px;

    &:hover {
      background-color: $blue;
      color: $white;
    }
  }
}

//
// Buttons
//

.PlayButton,
.PauseButton,
.PrevButton,
.NextButton,
.SoundOnButton,
.SoundOffButton {
  width: 40px;
  height: 40px;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;

  margin-right: 10px;
  background: $white;
  border: 2px solid $gray;
  border-radius: 100%;
  outline: none;

  &[disabled] {
    opacity: 0.5;
  }

  &.isEnabled {
    cursor: pointer;

    &:hover {
      border-color: $color-hover;

      .Icon-shape {
        fill: $color-hover;
      }
    }

    &:active {
      border-color: $color-active;

      .Icon-shape {
        fill: $color-active;
      }
    }

    &:focus {
      outline: none;
    }
  }
}

.Icon {
  width: $icon-size;
  height: $icon-size;
}

.Icon-shape {
  fill: $gray;
}


//
// Sliders
//

.ProgressBar,
.VolumeSlider {
  position: relative;
  background: #eee;
  border-radius: 4px;

  &-elapsed,
  &-value {
    background: $gray;
    border-radius: 4px;
  }

  &-seek {
    position: absolute;
    cursor: pointer;
  }

  &-handle {
    position: absolute;
    width: 16px;
    height: 16px;
    background: $green;
    border-radius: 100%;
    transform: scale(0);
    transition: transform 0.2s;
  }

  &.isSeekable,
  &.isEnabled {
    &:hover {
      .ProgressBar-handle,
      .VolumeSlider-handle {
        transform: scale(1);
      }

      .ProgressBar-intent,
      .VolumeSlider-intent {
        position: absolute;
        background: rgba(0,0,0,0.05);
      }
    }

    .ProgressBar-elapsed,
    .VolumeSlider-value {
      background: $green;
    }
  }
}

.ProgressBar {
  height: 8px;
  margin: 10px 0;

  &-elapsed {
    height: 100%;
  }

  &-seek {
    top: -6px;
    right: 0;
    bottom: -6px;
    left: 0;
  }

  &-handle {
    top: 0;
    margin: -4px 0 0 -8px;
  }

  &.isSeekable {
    &:hover {
      .ProgressBar-handle {
        transform: scale(1);
      }

      .ProgressBar-intent {
        top: 0;
        left: 0;
        height: 100%;
      }
    }
  }

  &.isLoading {
    opacity: 0.5;
    background: #ccc;
    animation: barLoading 2s infinite;
  }
}

.VolumeSlider {
  width: 8px;
  height: 100px;
  // margin: 10px 0;

  &-value {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  &-seek {
    left: -6px;
    top: 0;
    right: -6px;
    bottom: 0;
  }

  &-handle {
    left: 0;
    margin: 0 0 -8px -4px;
  }

  &.isEnabled {
    &:hover {
      .VolumeSlider-handle {
        transform: scale(1);
      }

      .VolumeSlider-intent {
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }
  }
}
