.notification-table{
  >tbody>tr>td{
    word-wrap: break-word;
    vertical-align:middle;
  }
  .body-td{
    width: 30%;
    & p{
      max-height: 500px;
      overflow-y: auto;
      margin: 0;
    }
  }
  button{
    width: 100%;
    font-size: 12px;
    padding: 3px 6px;
  }
}

@media screen and (max-width: 1024px){
  .notification-table{
    button{
      font-size:10px;
      padding: 5px;
    }
  }
}