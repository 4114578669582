@import 'react-datepicker';
@import '../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap';
@import './bootswatch';
$fa-font-path: './fonts';
@import '../../../node_modules/font-awesome/scss/font-awesome';
@import '../../../node_modules/highlight.js/styles/mono-blue';
@import '../../../node_modules/amcharts3/amcharts/plugins/export/export';
@import '../../../node_modules/react-datepicker/dist/react-datepicker';
@import '../../../node_modules/react-select/dist/react-select';

@import 'inspinia/style';

@import './navbar';
@import './article-editor';
@import './poolscores';
@import './react-datepicker';
@import './toasts';
@import './notifications';
@import './announcement';

// Components
@import './components/navigation-tabs';
@import './components/force-graph';
@import './components/path-logger';
@import './components/player-controls';
@import './components/header';

// Pages
@import './pages/manageFiles';
@import './pages/posDashboard';
@import './pages/testsComponents';

@media (min-width: 1400px) {
  @include make-grid(xl);
}

// We own the license for amcharts, but you can't use the commercial version
// through npm. Solution: hide the watermark
[class*="amcharts"] [href*="amcharts"] {
  display: none !important;
}

.page-heading-h2 {
  i {
    font-size: 2em;
    line-height: 0.5em;
    margin-right: 0.2em;
  }

  .flagged-print-file {
    font-size: 1.1em;
    line-height: 1em;
    margin-left: 0.5em;
  }

  display: flex;
}

.page-heading {
  .btn-flag {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

.strong {
  font-weight: bold;
}

.form-group {
  &:not(.required) label {
    font-weight: normal;
  }

  &.required label:after {
    content: ' (required)';
  }
}

.hide-element {
  display: none;
}

.error {
  color: #f44336;
  font-weight: bold;
}

form {
  fieldset {
    border: 1px solid #DEDEDE;
    border-radius: $border-radius-small;
    margin: 0 2px;
    margin-bottom: 5px;
    padding: .35em .625em .75em;

    legend {
      margin-bottom: 0;
      padding: 0 5px;
      width: auto;
    }

    .row:last-child .form-group {
      margin-bottom: 0;
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.pb-container {
  .pb-button {
    background-color: $gray-lighter;

    span {
      font-size: 1.2em;
    }
  }
}

.well {
  background-color: #E4E4E4;
}

table.hoverClick {
  td {
    cursor: pointer;
  }
}

.health-status-green,
.health-status-yellow,
.health-status-red {
  background: black;
  border: 2px solid black;
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: middle;
}

.health-status-green {
  background: green;
}

.health-status-yellow {
  background: yellow;
}

.health-status-red {
  background: red;
}

#page-users {
  .user-table {
    max-width: 500px;
  }
}

#page-dealers {
  .dealers-table tbody tr {
    cursor: pointer;
  }

  .dealers-table tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

#page-dealers {
  .create-dealer {
    margin-bottom: 10px;
  }
}

#sign-in-modal {
  .modal-dialog {
    width: 350px;
  }
}

#page-dealer-details {
  .panel-heading {
    font-size: 14px;
  }

  .edit-dealer-info-btn {
    margin-left: 10px;
    margin-top: -5px;
  }
}

#page-dealer-details {
  .branches-list,
  .user-table {
    margin-top: 10px;
  }

  .admin-table-container {
    height: auto;
    max-height: 150px;
    overflow-x: auto;
  }
}

#page-dealer-details {
  .dealer-info {
    .row {
      div{
        word-wrap: break-word;
      }
    }
    .bottom-spacing {
      padding-bottom: 15px;
    }
    h3{
      min-height: 35px;
    }
  }
}

#branch-modal {
  .add-admin-container .dropdown-menu {
    height: auto;
    max-height: 200px;
    overflow-x: auto;
  }

  .user-table-container {
    height: auto;
    margin-top: 10px;
    max-height: 150px;
    overflow-x: auto;
  }
}

#page-users,
#page-dealer-details,
#branch-modal,
#page-dealers
{
  .modify-buttons .btn {
    float: right;
    margin-left: 8px;
  }
}

.key-value-list {
  list-style-type: none;
  padding: 0;

  & > li {
    margin-bottom: 6px;
  }

  .key {
    font-size: 14px;
    font-weight: 600;
  }
}

#page-utilities {
  .utility-container {
    margin-bottom: 10px;

    h2 {
      margin-top: 0;
    }
  }
}

#page-cms-editor {
  .preview-area {
    background: white;
    border-radius: $border-radius-small;
    margin-bottom: 30px;
    padding: 5px 10px;
  }

  .send-notification-checkbox {
    display: inline-block;
    margin-right: 20px;
    float: right;

    label {
      margin-right: 10px;
    }
  }
}

#page-health {
  h1 small,
  h2 small {
    color: inherit;
    display: inline-block;
    font-size: 50%;
    vertical-align: middle;
  }

  .summary {
    background: white;
    box-shadow: 0 0 1px #b4bcc2;
    padding: 0 15px;
    text-align: center;

    small {
      font-size: 70%;
    }

    > .row > [class*='col-'] {
      padding: 10px;
    }

    .row .row {
      text-align: left;
    }

    .cluster-status.green {
      background: #80FF80;
    }

    .cluster-status.yellow {
      background: yellow;
    }

    .cluster-status.red {
      background: red;
    }

    strong {
      display: block;
    }
  }

  table td:last-child {
    text-align: right;
  }
}

.pointer{
  cursor: pointer;
}

body, #body {
  height: 100%;
}

.spinner-page {
  height: 100%;
  min-height: 0 !important;
}

.centered-spinner {
  height: 30px;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 50%;
  transform: translateX(-15px), translateY(-15px);
  width: 30px;
}
.btn-group-padding{
  padding-bottom: 25px;
}

.editable-checkbox {
  label {
    font-weight: normal;
  }
}

.ibox--flex {
  display: flex;
  flex-direction: column;

  .ibox-title--flex {
    display: flex;
    flex-direction: row;
    align-items: center;

    .ibox-main-title--flex {
      flex-grow: 2;
    }

    * {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .ibox-content--flex {
    flex-grow: 2;
  }
}

#page-tools-transfer {
  .margin-bottom {
    margin-bottom: 20px;
  }
}

.pos-integrated-dealers {
  margin-bottom: 20px;

  &__row {
    margin-bottom: 20px;
  }
  .no-issue {
    color: $blue;
  }
  .possible-issue {
    color: $yellow;
  }

  .card-link {
    color: inherit;
  }
}

#page-tru-blue-claims {
  .claim-totals {
    border: 1px solid black;
    padding: 0;
    margin: 0px 15px;
    width: calc(100% - 30px);

    p {
      font-size: 14px;
      margin: 5px 0px;
    }
  }
}

.flag-legend {
  margin-top: 15px;
  margin-bottom: 0;
}

.print-files-legend {
  margin-right: 15px;
}

@import './admin-reports';